import { useState, useRef } from "react";
import "../App.css";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "./Modal";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Team() {
  const ambassadorsSwiperRef = useRef(null);

  const [teamInnerData, setTeamInnerData] = useState(false);

  const unSetTeamData = () => {
    setTeamInnerData(false);
  };

  const ambassadors = [
    {
      name: "Dan Alexa",
      title: "Football",
      image: "Dan-Alexa.png",
      twitter: "https://twitter.com/DanAlexa79",
      about:
        "I am confident that Emorya Sport will help us all get a better and healthier life.",
    },
    {
      name: "Eric de Oliveira Pereira",
      title: "Football",
      image: "Eric-de-Oliveira-Pereira.png",
      facebook: "https://www.facebook.com/oliver27rj",
      twitter: "https://twitter.com/Eric_Oliver27",
      instagram: "https://www.instagram.com/oliver27rj",
      about:
        "The combination of the potential financial gain from this remarkable project and my passion for participating in sporting activities influenced me to join this groundbreaking initiative.",
    },
    {
      name: "Gabriel Tamas",
      title: "Football",
      image: "Gabriel-Tamas.png",
      instagram: "https://www.instagram.com/gabrieltamasofficial",
      about:
        "I'm joining this team to make a real change in Romanian sports and people's lives. Let's take this journey together for a brighter future!",
    },
    {
      name: "Stefan Ciuculescu",
      title: "Football",
      image: "Stefan-Ciuculescu.png",
      facebook: "https://www.facebook.com/Stefan33Ciuculescu",
      instagram: "https://www.instagram.com/stefanciuculescuofficial",
      about:
        "From the football field to the challenges of the jungle and hosting RxF galas, I am excited to be part of the Emorya project. A new adventure begins!",
    },
    {
      name: "Andreea Ana Beatrice",
      title: "Wrestling",
      image: "Andreea-Ana-Beatrice.png",
      instagram: "#",
      about:
        "With two gold medals at the European wrestling championships, I join the Emorya project to share my passion for performance and success.",
    },
    {
      name: "Alin Alexuc-Ciurariu",
      title: "Wrestling",
      image: "Alin-Alexuc-Ciurariu.png",
      facebook: "https://www.facebook.com/alinalexuc.ro",
      instagram: "https://www.instagram.com/alinalexuc",
      about:
        "As a double European wrestling champion, I have joined the revolutionary Emorya project to bring value to my community and the entire world.",
    },
    {
      name: "Eduard Kubat",
      title: "MTB-XCO, CX, ROAD",
      image: "Eduard-Kubat.png",
      instagram: "https://www.instagram.com/eduardkubat/",
      about:
        "As a double national champion in Cyclocross, I have decided to be part of the revolutionary Emorya project, considering my passion for new challenges.",
    },
    {
      name: "Daniel Natea",
      title: "Judo & Sambo",
      image: "Daniel-Natea.png",
      instagram: "https://instagram.com/daniel.natea/",
      about:
        "As a world champion in Sambo, I've chosen to join the revolutionary project Emorya, aiming to bring value to my community and contribute to significant global changes.",
    },
    {
      name: "Alexia Carutasu",
      title: "Volleyball",
      image: "Alexia-Carutasu.png",
      instagram: "https://instagram.com/alexiaioanacarutasu/",
      about:
        "This is also Emorya's message, that any effort can turn into a reward, and I am convinced that, together, we still have a lot to prove. On the field and off it!",
    },
    {
      name: "Constantin Budescu",
      title: "Football",
      image: "Constantin-Budescu.jpg",
      instagram: "https://www.instagram.com/budescuconstantin10",
      about:
        "With vast experience in Romanian football and numerous matches played for the national team, I am honored to be part of this revolutionary project, which was born in Romania.",
    },
    {
      name: "Eduard Spirache",
      title: "Invest Mania",
      image: "Eduard-Spirache.jpg",
      instagram: "https://www.instagram.com/eduardinvesteste/",
      about:
        "The opportunity that Emorya brings in the world with its ecosystem, should not be taken for granted, being rewarded for burning calories is a game changing idea.",
    },
    {
      name: "Cosmin Nedelea",
      title: "Ice Skating",
      image: "Cosmin-Nedelea.jpg",
      facebook: "https://www.facebook.com/people/Cosmin-Nedelea/100088276743494/",
      about:
        "I'm the national senior champion in individual and ice pentathlon, also in roller pentathlon with 4 national records and U23 world vice-champion in team sprint."
    },
    {
      name: "Andreea Ograzeanu",
      title: "Sprinter",
      image: "Andreea-Ograzeanu.png",
      facebook: "https://www.facebook.com/andreea.ograzeanu",
      instagram: "https://www.instagram.com/andreeaograzeanu?igsh=ZzMyamd3cXEwZ2Zk",
      about:
        "The fact that I have the opportunity to participate in this project as an ambassador is the perfect framework to encourage young people to engage in more physical activity."
    },
    {
      name: "Valentin Tanase",
      title: "Sprinter",
      image: "Valentin-Tanase.png",
      facebook: "https://www.facebook.com/valentin.tanase.902/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0",
      instagram: "https://www.instagram.com/tanasevalentin__/",
      about:
        " I am the fastest romanian in a sprint event. I have decided to be part of this project to help people who lack confidence in their themselves to unlock their potential ."
    },
  ];

  return (
    <>
      {teamInnerData ? (
        <>
          <Modal
            teamInnerData={teamInnerData}
            unSetTeamData={unSetTeamData}
          ></Modal>
        </>
      ) : (
        <></>
      )}

      <div
        className="section TeamSection"
        id="team-section"
        style={{
          height: "auto",
        }}
      >
        <div className="row row90 teamContainer">
          <div className="col-sm-12 sports TeamSection">
            <div className="TeamHeadingContainer">
              <div className="blueSubHeading">Meet our Ambassadors</div>
              <div className="whiteHeading">Ambassadors</div>
            </div>
          </div>

          <div
            className="col-sm-12 ambs showOnDesktop"
            style={{
              textAlign: "center",
              padding: 0,
              paddingTop: 20,
            }}
          >
            {ambassadors.map((team) => {
              return (
                <div className="ambsBox" key={team.image}>
                  <img
                    alt="Emorya"
                    src={require("../assets/ambassadors/" + team.image)}
                    style={{
                      width: "60%",
                    }}
                  />
                  <br />

                  {team.facebook && team.facebook !== "" ? (
                    <>
                      <a href={team.facebook} target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <></>
                  )}

                  {team.twitter && team.twitter !== "" ? (
                    <>
                      <a href={team.twitter} target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter"></i>
                      </a>
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <></>
                  )}

                  {team.instagram && team.instagram !== "" ? (
                    <>
                      <a href={team.instagram} target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <></>
                  )}

                  {team.linkedin && team.linkedin !== "" ? (
                    <>
                      <a href={team.linkedin} target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </>
                  ) : (
                    <></>
                  )}

                  <br />
                  <strong>{team.name}</strong>
                  <div className="blueSubHeading">{team.title}</div>
                  <div className="text">{team.about}</div>
                </div>
              );
            })}
          </div>

          <div
            className="mobileTeam ambs"
            style={{
              marginBottom: 200,
              maxHeight: 400,
            }}
          >
            <Swiper
              ref={ambassadorsSwiperRef}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              scrollbar={{ draggable: true }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
            >
              {ambassadors.map((team) => {
                return (
                  <SwiperSlide key={team.image}>
                    <div className="teamBox">
                      <img
                        alt="Emorya"
                        src={require("../assets/ambassadors/" + team.image)}
                        className="teamPic"
                      />
                      <br />

                      {team.facebook && team.facebook !== "" ? (
                        <>
                          <a
                            href={team.facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          &nbsp;&nbsp;
                        </>
                      ) : (
                        <></>
                      )}

                      {team.twitter && team.twitter !== "" ? (
                        <>
                          <a
                            href={team.twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                          &nbsp;&nbsp;
                        </>
                      ) : (
                        <></>
                      )}

                      {team.instagram && team.instagram !== "" ? (
                        <>
                          <a
                            href={team.instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                          &nbsp;&nbsp;
                        </>
                      ) : (
                        <></>
                      )}

                      {team.linkedin && team.linkedin !== "" ? (
                        <>
                          <a
                            href={team.linkedin}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </>
                      ) : (
                        <></>
                      )}

                      <br />
                      <strong>{team.name}</strong>
                      <br />
                      <div className="blueSubHeading">{team.title}</div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div
              style={{
                textAlign: "right",
                padding: 20,
                fontSize: 20,
                color: "#41faa4",
                width: "100%",
              }}
              onClick={() => {
                ambassadorsSwiperRef.current.swiper.slideNext();
              }}
            >
              <i className="fas fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
